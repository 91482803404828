const cookieConsentHandler = ((window, $) => {
	const init = () => {
		$(document).ready(() => {
			// Find all blocked iframes
			$("iframe[src*='x-content-blocked.html']").each(function() {
				const $iframe = $(this);
				const messageDiv = $(`
                    <div>
                        <p rel="cookies-box" style="display: block;">
                            Bitte akzeptieren Sie die Cookies, um das Video zu sehen.
                            <a class="open-ccm-widget" href="javascript:void(0);">Cookies link</a>
                        </p>
                    </div>
                `);

				// Replace iframe with the message
				$iframe.replaceWith(messageDiv);
			});

			// Click event for the settings button
			$(document).on('click', '.open-ccm-widget', function() {
				const $settingsButton = $('.ccm-settings-summoner--link');
				if ($settingsButton.length) {
					$settingsButton[0].dispatchEvent(
						new Event('click', { bubbles: true })
					);
					console.log('Settings button click event dispatched!');
				} else {
					console.warn('Settings button not found!');
				}
			});

			// Reload page when cookies are accepted or declined
			document.addEventListener('click', (event) => {
				if (
					event.target.matches(
						'.ccm--save-settings, .ccm--decline-cookies'
					)
				) {
					console.log(
						'Cookies preference changed, reloading page...'
					);
					console.log('staging test');
					setTimeout(() => {
						location.reload();
					}, 1000);
				}
			});
		});
	};

	return {
		init,
	};
})(window, jQuery);

export default cookieConsentHandler;
